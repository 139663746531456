// Import only the Bootstrap components we need
//import { Popover } from 'bootstrap';

// Create an example popover
/* document.querySelectorAll('[data-bs-toggle="popover"]')
  .forEach(popover => {
    new Popover(popover)
  }) */

// Or Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

/* * * * * * * * * * * * * * * * * * *
 * Flicking Carousel
 * * * * * * * * * * * * * * * * * * */
// JS Imports
import Flicking from "@egjs/flicking";
import { AutoPlay } from "@egjs/flicking-plugins";

import { Arrow } from "@egjs/flicking-plugins";
import { Pagination } from "@egjs/flicking-plugins";
//import Flicking, { MOVE_TYPE } from "@egjs/flicking";
import { Sync } from "@egjs/flicking-plugins";

// CSS imports
import "@egjs/flicking/dist/flicking.css"; // Supports IE10+, using CSS flex
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";

/* * * * * * * * * * * * * * * * * * *
 * All 'flicking' -MoodCarousel
 * https://naver.github.io/egjs-flicking/docs
 * * * * * * * * * * * * * * * * * * */

if (document.querySelector('#flickMood')) {
    const flicking = new Flicking("#flickMood", {
        circular: true,
        panelsPerView: 1,
        gap: 10
    });

    flicking.addPlugins(new AutoPlay({
        duration: 3000,
        direction: "NEXT",
        stopOnHover: true
    }));
    flicking.addPlugins(new Arrow());
    flicking.addPlugins(new Pagination({
        type: "bullet"
    }));
}

/* * * * * * * * * * * * * * * * * * *
 * All 'flicking' ProductCarousel
 * * * * * * * * * * * * * * * * * * */

if (document.querySelector('#flickProd')) {
    const flicking = new Flicking("#flickProd", {
        circular: true,
        //duration: 5000,
        //horizontal: true,
        //adaptive: true, //not working
        panelsPerView: 1
    });

    flicking.addPlugins(new AutoPlay({
        duration: 7500,
        direction: "NEXT",
        stopOnHover: true
    }));
    flicking.addPlugins(new Pagination({
        type: "bullet"
    }));
}

/* * * * * * * * * * * * * * * * * * *
 *  progressive-image
 * * * * * * * * * * * * * * * * * * */
import 'progressive-image.js/dist/progressive-image.js';
import 'progressive-image.js/dist/progressive-image.css';

/* * * * * * * * * * * * * * * * * * *
 *  AOS
 * * * * * * * * * * * * * * * * * * */
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init({
    duration: 1200, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
});

/* * * * * * * * * * * * * * * * * * *
 *  Start migration FH...
 * * * * * * * * * * * * * * * * * * */
window.addEventListener('DOMContentLoaded', event => {

    // Navbar shrink function
    var navbarShrink = function () {
        const navbarCollapsible = document.body.querySelector('#mainNav');
        if (!navbarCollapsible) {
            return;
        }
        if (window.scrollY === 0) {
            navbarCollapsible.classList.remove('navbar-shrink')
        } else {
            navbarCollapsible.classList.add('navbar-shrink')
        }

    };

    // Shrink the navbar
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener('scroll', navbarShrink);

    //  Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector('#mainNav');
    if (mainNav) {
        new bootstrap.ScrollSpy(document.body, {
            target: '#mainNav',
            rootMargin: '0px 0px -40%',
        });
    };

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(
        document.querySelectorAll('#navbarResponsive .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
        responsiveNavItem.addEventListener('click', () => {
            if (window.getComputedStyle(navbarToggler).display !== 'none') {
                navbarToggler.click();
            }
        });
    });

});

/* * * * * * * * * * * * * * * * * * *
 * back-to-top
 * * * * * * * * * * * * * * * * * * */

let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
    scrollFunction();
};

function scrollFunction() {
    if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
    ) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}